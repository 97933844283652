export function camelize(inputString) {
    return inputString.replace(/-./g, (x) => x[1].toUpperCase());
}
export function getDistinctArray(collection) {
    return Array.from(new Set(collection));
}
export const getFederationsEntrypointUrl = (scriptEl) => {
    const scriptUrl = new URL(scriptEl.src);
    return scriptUrl.href.replace('/modulesLoader.js', '');
};
